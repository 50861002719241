import { EditorSDK, DesignVariables, ComponentRef } from '@wix/platform-editor-sdk';
import { PlanListWidgetRole } from '@wix/pricing-plans-common/blocks';
import { findComponentByRole } from './widget';

const TITLE_FONT_SIZE = '36px';
const SUBTITLE_FONT_SIZE = '20px';

const updatedTitleStyles = {
  properties: {
    textDecoration: 'none',
    fontSize: TITLE_FONT_SIZE,
    textAlign: 'center',
    color: 'color_15',
    lineHeight: 'normal',
    letterSpacing: '0em',
  },
  propertiesSource: {
    textDecoration: 'value',
    fontSize: 'value',
    textAlign: 'value',
    color: 'theme',
    lineHeight: 'value',
    letterSpacing: 'value',
  },
  groups: {},
};

const subtitleStyles = {
  properties: {
    textDecoration: 'none',
    fontStyle: 'normal',
    color: 'color_15',
    textAlign: 'center',
    letterSpacing: '0em',
    fontSize: SUBTITLE_FONT_SIZE,
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  propertiesSource: {
    textDecoration: 'value',
    fontStyle: 'value',
    color: 'theme',
    textAlign: 'value',
    letterSpacing: 'value',
    fontSize: 'value',
    fontWeight: 'value',
    lineHeight: 'value',
  },
  groups: {},
};

/**
 * Overrides design varibales to take into acocunt additional margins in mobile on classic editor
 * and font size due to classic not supporting 'Custom Scale" setting from Blocks Editor.
 */
export async function setPlanCardListDefaultsOnClassic({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) {
  const oldDesignVariables = await editorSDK?.document.application.appStudioWidgets.designVariables.get('', {
    widgetRef: componentRef,
  });
  const newValues = {
    ...oldDesignVariables,
    horizontalPadding: { unit: 'px', value: 0 },
    titleSecondColumnWidth: { unit: 'percentage', value: 100 },
    subtitleSecondColumnWidth: { unit: 'percentage', value: 100 },
  } as DesignVariables;
  await editorSDK?.document.application.appStudioWidgets.designVariables.set('', {
    widgetRef: componentRef,
    newValues,
  });

  await updateTitleStyleOnClassic({ editorSDK, componentRef });
  await updateSubtitleStyleOnClassic({ editorSDK, componentRef });
}

async function updateTitleStyleOnClassic({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) {
  const title = await findComponentByRole({
    editorSDK,
    controllerRef: componentRef,
    role: PlanListWidgetRole.Title,
  });

  const oldTitleStyles = await editorSDK.document.components.style.get('', { componentRef: title! });
  return editorSDK.document.components.style.updateFull('', {
    componentRef: title!,
    style: {
      ...oldTitleStyles,
      style: updatedTitleStyles,
    },
  });
}

async function updateSubtitleStyleOnClassic({
  editorSDK,
  componentRef,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}) {
  const subtitle = await findComponentByRole({
    editorSDK,
    controllerRef: componentRef,
    role: PlanListWidgetRole.Subtitle,
  });

  const oldSubtitleStyles = await editorSDK.document.components.style.get('', { componentRef: subtitle! });
  await editorSDK.document.components.style.updateFull('', {
    componentRef: subtitle!,
    style: {
      ...oldSubtitleStyles,
      style: subtitleStyles,
    },
  });
}
